import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { api } from '../../api/api';
import moment from 'moment';
import { useAuth } from '../../context/AuthContext';
// import addPublicAccessToFolder from './AddPublicPolicyS3';
import { SquareDashed, PerspectiveView, Settings, Edit } from 'iconoir-react'; // Import icons
import AnalyticsSection from './AnalyticsSection';
import SettingsLMS from './SettingsLMS';
import { displayInfoMessage } from '../../utils/helpers';

const DeployLMS = () => {
  const { user } = useAuth();
  //const S3_BUCKET_NAME = 'slidesdeck' // Import S3_BUCKET_NAME from environment variables
  const [activeTab, setActiveTab] = useState('overview');
  //const [setInfoMessage] = useState('');
  const [organization, setOrganization] = useState(null);
  const [editingCourse, setEditingCourse] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  
  // // Function to handle Share Link button click
  // const handleShareLinkClick = async (projectId) => {
  //   try {
  //     await addPublicAccessToFolder(S3_BUCKET_NAME, user.user_id, projectId);
  //     console.log('Bucket configured for static hosting successfully');

  //     // Construct the public URL with index.html
  //     const publicUrl = `https://${S3_BUCKET_NAME}.s3.amazonaws.com/${user.user_id}/LMS_projects/${projectId}/index.html`;
  //     console.log('Public URL:', publicUrl);

  //     // Display the URL in the UI
  //     setInfoMessage(`Public URL: ${publicUrl}`);
  //   } catch (err) {
  //     console.error('Error configuring bucket:', err);
  //   }
  // };

  useEffect(() => {
    const fetchOrg = async () => {
      if (!user || !user.email) {
        console.warn("User is not available yet.");
        return;
      }

      try {
        const response = await api.get(`/api/organizations?filters[OrgAdmin][$eq]=${user.email}&populate=*`);
        const orgData = response.data;
        setOrganization(orgData);
      } catch (error) {
        console.error("Error fetching organizations", error);
      }
    };

    fetchOrg();
  }, [user]);
  

  const handleEditClick = (course) => {
    if (editingCourse === course.id) {
      // If already editing, save the changes
      handleSaveClick(course.id);
    } else {
      // Start editing
      setEditingCourse(course.id);
      setEditedTitle(course.attributes.title);
      setEditedDescription(course.attributes.description);
    }
  };

  const handleSaveClick = async (courseId) => {
    try {
      const response = await api.put(`/api/courses/${courseId}?populate=organization`, {
        data: {
          title: editedTitle,
          description: editedDescription,
        },
      });
      displayInfoMessage('Course updated successfully', 'success');
      setEditingCourse(null);
      console.log("response", response);
    } catch (error) {
      console.error('Error updating course:', error);
    }
  };

  return (
    <div className="flex h-screen bg-custom-skyBlue">
      <Sidebar />

      <div className="flex-1 flex flex-col ">
        <TopBar />
        <div className="flex-1 flex bg-custom-skyBlue p-4 overflow-auto">
          <aside className="w-1/4 p-4">
            <nav>
              <ul>
                <li>
                  <button
                    className={`w-full text-left p-2 flex items-center space-x-2 ${activeTab === 'overview' ? 'bg-gray-200' : ''}`}
                    onClick={() => setActiveTab('overview')}
                  >
                    <SquareDashed className="w-6 h-6" />
                    <span className="text-sm mb-1 font-bold">Overview</span>
                  </button>
                </li>
                <li>
                  <button
                    className={`w-full text-left p-2 flex items-center space-x-2 ${activeTab === 'analytics' ? 'bg-gray-200' : ''}`}
                    onClick={() => setActiveTab('analytics')}
                  >
                    <PerspectiveView className="w-6 h-6" />
                    <span className="text-sm mb-1 font-bold">Analytics</span>
                  </button>
                </li>
                <li>
                  <button
                    className={`w-full text-left p-2 flex items-center space-x-2 ${activeTab === 'settings' ? 'bg-gray-200' : ''}`}
                    onClick={() => setActiveTab('settings')}
                  >
                    <Settings className="w-6 h-6" />
                    <span className="text-sm mb-1 font-bold">LMS Settings</span>
                  </button>
                  {/* Add more buttons as needed */}
                </li>
              </ul>
            </nav>
          </aside>
          <main className="flex-1 bg-white p-4 overflow-auto">
            {/* Content based on activeTab */}
            {activeTab === 'overview' && (
              <div className="w-full mx-auto">
                {organization && organization.length > 0 ? (
                  organization.map((org) => (
                    org.attributes.courses.data.map((course) => (
                      <div key={course.id} className="flex gap-2 bg-white border border-gray-300 rounded-xl overflow-hidden items-center justify-start mb-4">
                        <div className="relative w-32 h-32 flex-shrink-0">
                          <img
                            className="absolute left-0 top-0 w-full h-full object-cover object-center transition duration-50"
                            loading="lazy"
                            src={`https://backend.ailms.co/${course.attributes.cover}`}
                            alt={course.attributes.title}
                          />
                        </div>
                        <div className="flex flex-col">
                          <div className="flex items-center">
                            {editingCourse === course.id ? (
                              <input
                                className="text-md font-bold"
                                value={editedTitle}
                                onChange={(e) => setEditedTitle(e.target.value)}
                              />
                            ) : (
                              <p className="text-md font-bold" onClick={() => handleEditClick(course)}>
                                {course.attributes.title}
                              </p>
                            )}
                            <button onClick={() => handleEditClick(course)}>
                              {editingCourse === course.id ? (
                                <span className="text-green-500">Save</span> // Change icon to Save
                              ) : (
                                <Edit className="w-3 h-3 cursor-pointer" /> // Edit icon
                              )}
                            </button>
                          </div>
                          <p className="text-gray-500 text-xxs">
                            Deployed on: {moment(course.attributes.createdAt).format('MMM Do YYYY hh:mm a')}
                          </p>
                          <p className="text-gray-500 text-xxs mt-1 font-bold">Course Description</p>
                          <div className="flex items-center">
                            {editingCourse === course.id ? (
                              <textarea
                                className="text-gray-500 text-xxs w-full"
                                value={editedDescription}
                                onChange={(e) => setEditedDescription(e.target.value)}
                              />
                            ) : (
                              <p className="text-gray-500 text-xxs mb-1 overflow-hidden text-ellipsis max-h-12" onClick={() => handleEditClick(course)}>
                                {course.attributes.description}
                              </p>
                            )}
                            <Edit className="w-3 h-3 cursor-pointer" onClick={() => handleEditClick(course)} />
                          </div>
                          {editingCourse === course.id && (
                            <button className="bg-green-500 text-white text-xxs px-2 py-1 rounded" onClick={() => handleSaveClick(course.id)}>
                              Save
                            </button>
                          )}
                          <div className="flex space-x-2 items-center mb-2">
                            <button
                              className="bg-blue-500 text-white text-xxs px-2 py-1 rounded"
                              onClick={() => {
                                const previewWindow = window.open("", "_blank", "width=800,height=600");
                                previewWindow.document.write(`
                                  <html>
                                    <head>
                                      <title>Project Preview</title>
                                    </head>
                                    <body>
                                      <div style="background-color: yellow; padding: 10px; text-align: center;">
                                        <strong>Preview only: Progress will not be saved</strong>
                                      </div>
                                      <iframe src="${course.attributes.course_url}" style="width: 100%; height: 90vh;" frameborder="0"></iframe>
                                    </body>
                                  </html>
                                `);
                              }}
                            >
                              Preview Project
                            </button>
                            {/* <button className="bg-red-500 text-white text-xxs px-2 py-1 rounded">Delete</button> */}
                            <div className="flex items-center">
                              <label htmlFor={`Toggle-${course.id}`} className="inline-flex items-center rounded-md cursor-pointer dark:text-gray-100">
                                <input
                                  id={`Toggle-${course.id}`}
                                  type="checkbox"
                                  className="hidden peer"
                                  defaultChecked={course.attributes.status === 'active'}
                                  onChange={async (e) => {
                                    const isActive = e.target.checked;
                                    try {
                                      const response = await api.put(`/api/courses/${course.id}`, {
                                        data: {
                                          status: isActive ? 'inactive' : 'active',
                                        },
                                      });
                                      console.log("response", response);
                                      displayInfoMessage(`${course.attributes.title} is now ${isActive ? 'inactive' : 'active'}`, 'info');
                                    } catch (error) {
                                      console.error('Error updating course status:', error);
                                    }
                                  }}
                                />
                                <span className="text-xxs px-2 py-1 rounded-l dark:bg-blue-500 peer-checked:dark:bg-gray-700">Active</span>
                                <span className="text-xxs px-2 py-1 rounded-r dark:bg-gray-700 peer-checked:dark:bg-blue-500">Inactive</span>
                              </label>
                            </div>
                            <button
                              className="bg-blue-500 text-white text-xxs px-2 py-1 rounded"
                              onClick={() => {
                                navigator.clipboard.writeText(`https://ailms.co/lms/${org.attributes.Name}`)
                                  .then(() => {
                                    displayInfoMessage('Link copied to clipboard', 'success');
                                  })
                                  .catch(err => {
                                    console.error('Failed to copy text: ', err);
                                  });
                              }}
                            >
                              User Login Portal 
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ))
                ) : (
                  <p>No courses have been deployed yet.</p>
                )}
              </div>
            )}
            {activeTab === 'analytics' && <AnalyticsSection/>}
            {activeTab === 'settings' && <SettingsLMS/>}
            {/* Add more content components as needed */}
          </main>
        </div>
      </div>
    </div>
  );
};

export default DeployLMS;
