import React, { useState, useEffect, useRef } from 'react';
import { api } from '../../api/api';
import { useAuth } from '../../context/AuthContext';
import { TwitterPicker } from 'react-color';
import { displayInfoMessage } from '../../utils/helpers';
import { compressAndResizeImage } from '../../utils/compressImages';

const SettingsLMS = () => {
  const { user } = useAuth();
  const [organization, setOrganization] = useState(null);
  const [newOrgName, setNewOrgName] = useState('');
  const [newOrgLogo, setNewOrgLogo] = useState(null);
  const [newDomain, setNewDomain] = useState('');
  const [newPrimaryColor, setNewPrimaryColor] = useState('');
  const [newSecondaryColor, setNewSecondaryColor] = useState('');
  const [newLoginPortal] = useState('');
  const [showPrimaryPicker, setShowPrimaryPicker] = useState(false);
  const [showSecondaryPicker, setShowSecondaryPicker] = useState(false);
  const fileInputRef = useRef();

  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const response = await api.get(`/api/organizations?filters[OrgAdmin][$eq]=${user.email}`);
        const orgData = response.data[0];
        // Check if the user has an organization

        setOrganization({ id: orgData.id, ...orgData.attributes });
      } catch (error) {
        console.error("Error fetching organizations", error);
      }
    };

    fetchOrg();
  }, [user.email, organization?.organizations]);

  const updateOrganization = async () => {
    try {
      await api.put(`/api/organizations/${organization.id}`, {
        data: {
          Name: newOrgName || organization.Name,
          Domain: newDomain || organization.Domain,
          PrimaryColor: newPrimaryColor || organization.PrimaryColor,
          SecondaryColor: newSecondaryColor || organization.SecondaryColor,
          Logo: newOrgLogo || organization.Logo,
          LoginPortal: newLoginPortal || organization.LoginPortal
        }
      });
      fileInputRef.current.value = '';
      displayInfoMessage("Organization data updated", "success");
    } catch (error) {
      console.error("Error updating organization", error);
    }
  };

  return (
    <div className="bg-white p-6">
      <main className="flex-1">
        <h2 className="text-2xl font-semibold mb-6 border-b pb-2">Organization Details</h2>
        {organization ? (
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col mb-4">
              <strong className="mb-2">Company Name:</strong>
              <input
                type="text"
                value={newOrgName || organization.Name}
                onChange={(e) => setNewOrgName(e.target.value)}
                className="border p-3 rounded mb-4"
              />
              <strong className="mb-2">Login Portal:</strong>
              <input
                type="text"
                value={`https://ailms.co/lms/${organization.Name}`}
                readOnly
                className="border p-3 rounded mb-4"
                onClick={() => {
                  navigator.clipboard.writeText(`https://ailms.co/lms/${organization.Name}`);
                  displayInfoMessage('Link copied to clipboard', 'success');
                }}
              />
              <strong className="mb-2">Domain (for user login):</strong>
              <input
                type="text"
                value={newDomain || organization.Domain}
                onChange={(e) => setNewDomain(e.target.value)}
                className="border p-3 rounded mb-4"
              />
              <div className="flex space-x-4 mb-4">
                <div>
                  <strong className="mb-2">Primary Color:</strong>
                  <div
                    className="h-8 w-8 rounded-full cursor-pointer"
                    style={{ backgroundColor: newPrimaryColor || organization.PrimaryColor }}
                    onClick={() => setShowPrimaryPicker(!showPrimaryPicker)}
                  />
                  {showPrimaryPicker && (
                    <TwitterPicker
                      color={newPrimaryColor || organization.PrimaryColor}
                      onChangeComplete={(color) => {
                        setNewPrimaryColor(color.hex);
                        setShowPrimaryPicker(false);
                      }}
                    />
                  )}
                </div>
                <div>
                  <strong className="mb-2">Secondary Color:</strong>
                  <div
                    className="h-8 w-8 rounded-full cursor-pointer"
                    style={{ backgroundColor: newSecondaryColor || organization.SecondaryColor }}
                    onClick={() => setShowSecondaryPicker(!showSecondaryPicker)}
                  />
                  {showSecondaryPicker && (
                    <TwitterPicker
                      color={newSecondaryColor || organization.SecondaryColor}
                      onChangeComplete={(color) => {
                        setNewSecondaryColor(color.hex);
                        setShowSecondaryPicker(false);
                      }}
                    />
                  )}
                </div>
              </div>
              <img
                src={newOrgLogo ?? organization.Logo}
                alt="Organization Logo"
                className="h-12 w-12 mb-4 rounded-full shadow-lg"
              />
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      compressAndResizeImage(file).then((compressedImage) => {
                        const compressedReader = new FileReader();
                        compressedReader.onloadend = () => {
                          setNewOrgLogo(compressedReader.result)
                        };
                        compressedReader.readAsDataURL(compressedImage); 
                      });
                    };
                    reader.readAsDataURL(file);
                  }
                }}
                className="mb-4"
              />
              <button
                onClick={updateOrganization}
                className="bg-blue-500 text-white p-3 rounded"
              >
                Save All Changes
              </button>
            </div>
            <div className="flex flex-col space-y-4 mb-4">
              <p><strong>Admin Email:</strong> {organization.OrgAdmin}</p>
            </div>
          </div>
        ) : (
          <p>Loading organization details...</p>
        )}
      </main>
    </div>
  );
};

export default SettingsLMS;
